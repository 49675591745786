import React, {useEffect, useState} from "react";

import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";


import {NavLink, useNavigate, useParams} from "react-router-dom";
import GenericButton from "../../components/Buttons/GenericButton";
import orderService from "../../services/orderService";
import GetOrderModel from "../../dto/order/GetOrderModel";
import {useTranslation} from "react-i18next";
import DeliverySavedAddress
    from "../../components/OrderProducts/OrderProductsDelivery/delivery/DeliverySavedAddress/DeliverySavedAddress";
import {ProductEntity} from "../../interfaces/Product/ProductEntity";
import TotalOrder from "../../components/TotalOrder/TotalOrder";
import TableOrder from "../../components/TableOrder/TableOrder";
import {FlexboxGrid, Panel} from "rsuite";

import KeyValuePaire from "../../components/KeyValuePaire/KeyValuePaire";
import TableOrderEdit from "../../components/TableOrder/TableOrderEdit";

import {DeliveryType} from "../../enums/DeliveryType";
import {CarrierType} from "../../enums/CarrierType";
import {OrderStatus} from "../../enums/OrderStatus";
import {DeliveryPayer} from "../../enums/DeliveryPayer";


import OrderTypes from "../../components/OrderTypes/OrderTypes";
import {useAppSelector} from "../../hooks";


import "./OrderSinglePage.sass"
import AuthProvider from "../../services/AuthProvider";
import {PermissionsConstants} from "../../const/Permissions";
import pageServices from "../../services/pageServices";


const OrderSinglePage:React.FC = () => {
    const {t} = useTranslation();

    let { orderId } = useParams();
    const navigate = useNavigate();

    const [order, setOrder] = useState<GetOrderModel>()
    const [editOrderDetails, setEditOrderDetails] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

    const rate = useAppSelector(state => state.rate.value)
    const currentLang = useAppSelector((state) => state.currentLang.value)


    const [viewReclamations, setViewReclamations] = useState(false)


    useEffect(() => {
        const permissions = AuthProvider.getPermissions()
        let permissionReclatation = permissions.includes(PermissionsConstants.ViewReclamations)
        setViewReclamations(permissionReclatation)

        if (orderId) {
            orderService.getOrder(+orderId).then(data => {
                setOrder(data)
                if (data.status == OrderStatus.New || data.status == OrderStatus.Processing){
                    setShowEdit(true)
                }
            })
        }

    }, [currentLang])

    const isEditState = () => {
        setEditOrderDetails(true)
    }

    const saveOrder = () => {
        setEditOrderDetails(false)
    }

    const [showEditContactData, setShowEditContactData] = useState(false)
    const editOrderContactData = () => {
        navigate(`/new-order/${orderId}`);
    }


    const downloadOrder = () => {

        if (orderId) {
            orderService.getOrderExel(+orderId).then(file => {
                const url = window.URL.createObjectURL(
                    file,
                );
                var link = document.createElement("a");
                link.setAttribute("href", url);
                link.setAttribute("download", `Order #${orderId}.xlsx`);
                document.body.appendChild(link); // Required for FF
                link.click();
                document.body.removeChild(link);
            })
        }

        /*let csv = "data:text/csv;charset=utf-8,";
        const universalBOM = "\uFEFF";
        csv += universalBOM; // + `${t('settlementCsv.date')},${t('settlementCsv.document')},${t('settlementCsv.startBalance')},${t('orderCsv.orderAmount')},${t('settlementCsv.paymentAmount')},${t('settlementCsv.finalBalance')}\n`;
        csv += `${t('orderCsv.orderNumber')},${order?.id}\n`;
        csv += `${t('orderCsv.orderDate')},${order?.orderDate + ' ' + order?.orderTime}\n`;
        csv += `${t('orderCsv.orderStatus')},${order?.status}\n`;
        csv += `${t('orderCsv.contactName')},${order?.contactName}\n`;
        csv += `${t('orderCsv.contactPhone')},${order?.contactPhone}\n`;
        csv += `${t('orderCsv.deliveryType')},"${order?.deliveryAddress.carrierType}, ${order?.deliveryAddress.deliveryType}"\n`;
        if(order?.deliveryAddress.carrierType != CarrierType.PickUp) {
            if (order?.deliveryAddress.deliveryType == DeliveryType.BranchDelivery) {
                csv += `${t('orderCsv.deliveryAddress')},"${order?.deliveryAddress.district}, ${order?.deliveryAddress.city}, ${order?.deliveryAddress.house}, ${order?.deliveryAddress.apartment}"\n`;
            } else {
                csv += `${t('orderCsv.deliveryAddress')},"${order?.deliveryAddress.district}, ${order?.deliveryAddress.city}, ${order?.deliveryAddress.house}, ${order?.deliveryAddress.apartment}"\n`;
            }
        } else {
            csv += `${t('orderCsv.pickupDate')},${order?.pickupDate}\n`;
            csv += `${t('orderCsv.pickupTime')},${order?.pickupTime}\n`;
            csv += `${t('orderCsv.carModel')},${order?.carModel}\n`;
            csv += `${t('orderCsv.carNumber')},${order?.carNumber}\n`;
        }
        csv += `${t('orderCsv.receiverName')},${order?.receiver.firstName} ${order?.receiver.lastName} ${order?.receiver.middleName}\n`;
        csv += `${t('orderCsv.receiverPhone')},${order?.receiver.phone}\n`;
        if(order?.shippingInvoiceNumber){
            csv += `${t('orderCsv.orderShippingInvoice')},${order?.shippingInvoiceNumber}\n`;
        }
        csv += order?.items.map(p => {
            const total = p.price * p.count;
            const profit = p.recommendedRetailPrice * p.count - total;
            return `${p.vendorCode},${p.productName},${p.lastBoughtOn},${p.recommendedRetailPrice},${p.price},${p.left},${p.count},${total},${profit}\n`;
        }).join("\n");
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "order.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
        document.body.removeChild(link);*/
    }

    const repeatOrder = () => {
        if(orderId) {
            navigate(`/copy-order/${orderId}`);
        }
    }

    const cancelOrder = () => {
        if (orderId) {
            orderService.cancelOrder(+orderId).then(() => {
                navigate('/my-orders');
            })
        }
    }

    const [content, setContent] = useState<string>('')

    useEffect(() => {
        pageServices.getPage('return-creation').then(data => {
            setContent(data.content)
        })
    }, [currentLang, content])


    return (
        <div className="my-order-single-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="back-link">
                    <NavLink to='/my-orders'>{t('orderSingle.toOrders')}</NavLink>
                </div>

                <div className="my-order-single-header">
                    <div className="my-order-single-id">{t('orderSingle.order')} {orderId}</div>
                    {order ? <OrderTypes status={order.status} /> : null }

                    <div className="my-order-single-download">
                        <button className="btn btn-lg download" onClick={downloadOrder}>{t('orderSingle.downloadOrder')}</button>
                    </div>

                    <div className="my-order-single-repeat">
                        <GenericButton generic={"repeat"} onClick={repeatOrder} isDisabled={false} showText={true} />
                    </div>

                    {showEdit ? <div className="my-order-single-decline">
                        <GenericButton generic={"decline"} onClick={cancelOrder} isDisabled={false} showText={true} />
                    </div> : null}

                </div>


                <div className="info-order-wrapper">

                    <div className="info-order-detail-wrapper">
                        <div className="col col-1">

                            {order?.contactName ?
                                <KeyValuePaire label={t('finishedOrders.contactData')} value={order.contactName} />
                                : null}

                            {order?.contactPhone ?
                                <KeyValuePaire label={t('finishedOrders.phone')} value={order.contactPhone} />
                                : null}

                            {order?.deliveryAddress || order?.isPickup ? <DeliverySavedAddress
                                paymentPickupData={order.isPickup? {deliveryPayer: order.deliveryPayer,
                                    orderToPay:order.cashOnDeliveryAmount,
                                    carModel: order.carModel,
                                    carNumber: order.carNumber,
                                    pickupDate: order.pickupDate,
                                    pickupTime: order.pickupTime}: null}
                                savedAddress={order.deliveryAddress} labels={true} /> : null}

                            {order?.shippingInvoiceNumber ?
                                <div className="invoiceShipping">
                                    <KeyValuePaire label={t('orderSingle.shippingInvoiceNumber')} value={order.shippingInvoiceNumber} />
                                </div>
                                : null}

                            {order?.deliveryPayer != null ?
                                <div className="key-value-info">
                                    <div className="label">{t('profile.payer')}</div>
                                    <div className="value">
                                        {order.deliveryPayer == DeliveryPayer.Receiver ? t('deliveryPayer.receiver') :  t('deliveryPayer.sender')}
                                    </div>
                                </div>
                                : null}

                        </div>
                        <div className="col col-2">
                            {order?.cashOnDeliveryAmount ?
                                <KeyValuePaire label={t('profile.sum')} value={order.cashOnDeliveryAmount} />
                                : null}

                            <div className="delivery-volume">
                                {order?.shippingPlaces ?
                                    <div className="delivery-volume-item">
                                        <KeyValuePaire label={t('orderSingle.shippingPlaces')} value={order.shippingPlaces} />
                                    </div>
                                    : null}
                                {order?.width ?
                                    <div className="delivery-volume-item">
                                        <KeyValuePaire label={t('orderSingle.width')} value={order.width} />
                                    </div>
                                    : null}
                                {order?.height ?
                                    <div className="delivery-volume-item">
                                        <KeyValuePaire label={t('orderSingle.height')} value={order.height} />
                                    </div>
                                    : null}
                                {order?.weight ?
                                    <div className="delivery-volume-item">
                                        <KeyValuePaire label={t('orderSingle.weight')} value={order.weight} />
                                    </div>
                                    : null}
                            </div>

                            {order?.receiver ?
                                <KeyValuePaire label={t('finishedOrders.contactPerson')} value={order.receiver.lastName + ' ' + order.receiver.firstName} />
                                : null}

                            {order?.receiver ?
                                <KeyValuePaire label={t('finishedOrders.contactPhone')} value={order.receiver.phone} />
                                : null}

                            {order?.comment ?
                                <KeyValuePaire label={t('finishedOrders.comments')} value={order.comment} />
                                : null}
                        </div>
                    </div>


                    {showEdit ? <GenericButton generic={"editOrder"} onClick={editOrderContactData} isDisabled={false} showText={true} /> : null}


                </div>

                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h2>{t('orderSingle.orderList')}</h2>
                    </FlexboxGrid.Item>
                    {/*{showEdit ? <FlexboxGrid.Item>
                        {editOrderDetails ?
                            <GenericButton generic={"save"} onClick={saveOrder} isDisabled={false} showText={true} />
                            :
                            <GenericButton generic={"edit"} onClick={isEditState} isDisabled={false} showText={true} />
                        }

                    </FlexboxGrid.Item> : null}*/}


                </FlexboxGrid>


                <div className="order-details-wrapper">
                    <div className="table-orders">
                        {editOrderDetails ?
                            order?.items ? <TableOrderEdit
                                    data={order?.items.map((item) => ProductEntity.CreateProductEntityOrder(item))}
                                />
                                : null
                            : order?.items ? <TableOrder
                                    data={order?.items.map((item) => ProductEntity.CreateProductEntityOrder(item))}
                                />
                                : null }
                    </div>

                    <div className="price-wrapper">
                        <div className="price-currency-wrapper">

                            <div className="delivery-volume">
                                <div className="delivery-volume-item">
                                    {order?.shippingPlaces ?
                                        <KeyValuePaire label={t('currency')} value={'USD'} />
                                        : null}
                                </div>
                                <div className="delivery-volume-item">
                                    {order?.width ?
                                        <KeyValuePaire label={t('exchangeRates')} value={rate} />
                                        : null}
                                </div>
                            </div>

                            <NavLink to={'/settlements/'} className="btn btn-usual">{t('orderSingle.viewSettlements')}</NavLink>

                        </div>

                        {order?.totalAmount ? <TotalOrder reserve={{orderId: order.id,
                            orderItems: order.items.map(i => ({product: {
                                    id: i.productId,
                                    vendorCode: i.vendorCode,
                                    photoUrl: '',
                                    name: i.productName,
                                    lastPurchaseDate: '',
                                    available: 0,
                                    recommendedRetailPrice: i.recommendedRetailPrice,
                                    price: i.price,
                                    lastPurchaseCount: 0,
                                    categoryId: 0
                                }, count: i.count}))}} showVat={true} /> : null}


                        {order?.status == 4 ?
                        <NavLink
                            to={`/create-return?id=${orderId}`}
                            className="btn btn-lg btn-createReturn"
                        >{t('orderSingle.createReturn')}</NavLink>
                            : null}

                        {viewReclamations && order?.status == 4 ?
                            <NavLink
                                to={`/create-reclamation?id=${orderId}`}
                                className="btn btn-usual btn-createComplaint"
                            >{t('orderSingle.createComplaint')}</NavLink>
                            : null}

                    </div>


                </div>

                <Panel header={t('return.returnTitle')} collapsible bordered>

                    <div dangerouslySetInnerHTML={{__html: content}}></div>

                </Panel>


            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default OrderSinglePage