import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";


import Header from "../../blocks/Header/Header";
import Footer from "../../blocks/Footer";
import GenericButton from "../../components/Buttons/GenericButton";
import KeyValuePaire from "../../components/KeyValuePaire/KeyValuePaire";
import {DateRangePicker} from "rsuite";
import {useTranslation} from "react-i18next";
import Paging from "../../interfaces/Pagination";
import transactionService from "../../services/transactionService";
import {Transaction} from "../../dto/transactions/Transaction";

import {DateRange} from "rsuite/DateRangePicker";
import moment from "moment";
import TableBottom from "../../components/TableBottom/TableBottom";
import TransactipnsTable from "../../components/SettlementsTable/TransactipnsTable";

import "./SettlementsPage.sass"
import {useAppSelector} from "../../hooks";
import {addMonths} from "rsuite/cjs/utils/dateUtils";

const { afterToday } = DateRangePicker;

const SettlementsPage:React.FC = () => {
    const {t} = useTranslation();
    let currentLang: string = useAppSelector((state) => state.currentLang.value);
    const balance = useAppSelector<number>(state => state.user.value.balance)
    const currUser = useAppSelector((state) => state.user.value);
    const [selectedDates, setSelectedDates] = useState<DateRange | null>([new Date(), addMonths(new Date(), -1)]);

    const [downloadTransactionsState, setDownloadTransactionsState] = useState(true)
    const [settlements, setSettlements] = useState<Transaction>();
    const [paging, setPaging] = useState<Paging>({
        totalPageCount: 1,
        totalItemCount: 1,
        currentPageNumber: 1,
        pageSize: 25
    });
    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');
    const [selectedRows, setSelectedRows] = useState<number[]>([]);

    const [pageSize, setPageSize] = useState(25)
    const [currentPage, setCurrentPage] = useState(1)
    const [totalItems, setTotalItems] = useState(1)
    const changePageSize = (key: number) => {
        setPageSize(key)
    }
    const changeActivePage = (key: number) => {
        setCurrentPage(key)
    }


    useEffect(() => {
        transactionService.getTransactions(fromDate, toDate, currentPage, pageSize).then(data => {
            setSettlements(data)
            setPaging({
                totalPageCount: data.items.totalPageCount,
                totalItemCount: data.items.totalItemCount,
                currentPageNumber: data.items.currentPageNumber,
                pageSize: data.items.pageSize
            })
            setTotalItems(data.items.totalItemCount);
        })
    }, [pageSize, currentPage, fromDate, toDate, currentLang])

    const setActivePage = (page: number) => {
        setPaging({...paging, currentPageNumber: page})
    }


    const onSettlementsSelectionChange = (ids: number[]) => {

        setSelectedRows(ids);

        if(Object.keys(ids).length != 0){
            setDownloadTransactionsState(false)
        }
        else {
            setDownloadTransactionsState(true)
        }

    }

    const navigate = useNavigate()
    const topUpBalance = () => {
        navigate('/balance')
    }

    const dateRangeChange = (value: DateRange | null) => {
        if(value) {
            const from = moment(value[0]).toISOString();
            const to = moment(value[1]).toISOString();
            setFromDate(from);
            setToDate(to);
        }
        else {
            setFromDate('');
            setToDate('');
        }
        setSelectedDates(value);
    }

    const downloadSettlements = () => {

        let newKeys = Object.keys(selectedRows).map(item => +item)

        transactionService.getTransactionsExel(newKeys, fromDate == ''? null: fromDate,
            toDate == ''? null: toDate).then(file => {
            const url = window.URL.createObjectURL(
                file,
            );
            var link = document.createElement("a");
            link.setAttribute("href", url);
            link.setAttribute("download", "settlements.xlsx");
            document.body.appendChild(link); // Required for FF
            link.click();
            document.body.removeChild(link);

        })

        /*if (selectedRows.length == 0) return;
        const selectedSettlements = settlements!.items.items.filter(s => selectedRows.includes(s.id));
        let csv = "data:text/csv;charset=utf-8,";
        const universalBOM = "\uFEFF";
        csv += universalBOM + `${t('settlementCsv.date')},${t('settlementCsv.document')},${t('settlementCsv.startBalance')},${t('orderCsv.orderAmount')},${t('settlementCsv.paymentAmount')},${t('settlementCsv.finalBalance')}\n`;
        csv += selectedSettlements.map(s => {
            const date = s.date + ' ' + s.time;
            return `${date},${s.documentName},${s.initialBalance},${s.credit},${s.debit},${s.finalBalance}`;
        }).join("\n");
        var encodedUri = encodeURI(csv);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "settlements.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);*/
    }

    return (
        <div className="settlements-wrapper page-wrapper">
            <Header></Header>
            <main>
                <div className="page-container">
                    <h2>{t('transactions.transactions')}</h2>

                    <div className="settlements-info-wrapper">
                        <div className="settlements-rest">
                            <div className="key-value-info">
                                <div className="label">{t('transactions.available')}</div>
                                <div className={balance > 0 ? "value debt" : "value"}>
                                    <span>{settlements? Number(-balance).toFixed(2) : 0} {t(currUser.currency)}</span>
                                    {process.env.REACT_APP_LOCATION === 'ua' ?<GenericButton generic={"topUpBalance"} onClick={topUpBalance} isDisabled={false} showText={true} />: null}
                                </div>
                            </div>
                        </div>

                        <div className="settlements-sum-bought">
                            <KeyValuePaire label={t('transactions.bought')} value={(settlements? settlements.totalCredit: 0) + t(currUser.currency)} />
                        </div>
                        <div className="settlements-sum">
                            <KeyValuePaire label={t('transactions.boughtSum')} value={(settlements? settlements.totalDebit: 0) + t(currUser.currency)} />
                        </div>
                        <div className="settlements-daterange">
                            <div className="date_range-wrapper">
                                <label className="date-range-label">{t('transactions.period')}</label>
                                <DateRangePicker
                                    showOneCalendar
                                    character={' - '}
                                    format={('dd.MM.yyyy')}
                                    placeholder={t('transactions.format')}
                                    ranges={[]}
                                    onChange={dateRangeChange}
                                    value={selectedDates}
                                    disabledDate={afterToday!()} />
                            </div>
                            <button className="btn btn-lg download-date-range"
                                    onClick={downloadSettlements}
                                    disabled={downloadTransactionsState}>
                                {t('btn.download')}
                            </button>
                        </div>
                    </div>

                        {settlements?.items.items ?
                            <div  className="table-responsive transactions-table">
                            <TransactipnsTable
                                data={settlements.items.items}
                                onRowSelectionChange={onSettlementsSelectionChange}

                        />
                            </div> : null}

                    <TableBottom
                        onPageSizeChange={changePageSize}
                        onActivePageChange={changeActivePage}
                        totalItemCount={totalItems}
                        pageSize={pageSize}
                        currentPage={currentPage}
                    />

                </div>
            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default SettlementsPage