import React, {useEffect, useState} from "react";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {NavLink} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../hooks";

import {useTranslation} from "react-i18next";

import Cart from "./Cart"
import Reserve from "./Reserve";

import {CartOrder} from "../../interfaces/CartProduct";

import EmptyCart from "../../components/EmptyCart/EmptyCart";
import ExchangeRates from "../../components/ExchangeRates/ExchangeRates";
import TotalOrder from "../../components/TotalOrder/TotalOrder";

import {changeActiveTab} from "../../slices/activeSidebarTabSlice";
import {addCartOrderAsync} from "../../slices/actions/cartAsyncActions";

import "react-tabs/style/react-tabs.css";
import "./Sidebar.sass"

const Sidebar: React.FC = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const productsCart = useAppSelector<CartOrder[]>((state) => state.cart.value);
    const productsReserve = useAppSelector<CartOrder>((state) => state.reserve.value);
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)
    const location = process.env.REACT_APP_LOCATION ?? 'ua';

    let totalProducts = 0;

    if (productsCart.length != 0) {
        productsCart[activeOrder].orderItems.forEach((product) => {
            totalProducts += product.count
        })
    }


    const [productsCount, setProductsCount] = useState(totalProducts)

    useEffect(() => {
        let totalProducts = 0;
        if (productsCart.length != 0) {
            productsCart[activeOrder].orderItems.forEach((product) => {
                totalProducts += product.count
            })
        }

        setProductsCount(totalProducts)
    }, [productsCart, activeOrder])

    let totalReserve = 0;
    const [reserveCount, setReserveCount] = useState(totalReserve)

    useEffect(() => {
        let totalReserve = 0;
        if (productsReserve.orderItems.length != 0) {
            productsReserve.orderItems.forEach((product) => {
                totalReserve += product.count
            })
        }

        setReserveCount(totalReserve)
    }, [productsReserve])


    /*useEffect(() => {
        if(deviceWidth <= 860) {
            setActiveCart(false)
        }
    }, [])*/

    const activeTab = useAppSelector<number>(state => state.activeTab.value)


    const deviceWidth =  window.innerWidth
    /*const [activeCart, setActiveCart] = useState(true)*/
    /*const openCart = () => {
        if(deviceWidth <= 860) {
            setActiveCart(activeCart => !activeCart)
        }
    }*/

    const addOrderAction = () => {
        dispatch(addCartOrderAsync())
    }

    const checkProductCount = (e: any) => {
        if(productsCount == 0)  e.preventDefault()
    }

    return (
        <div className={"basket-wrapper"}>

            <Tabs selectedIndex={activeTab} onSelect={(index) => { dispatch(changeActiveTab(index)); return true } }>
                <TabList>
                    <Tab><span className={"basket"}></span>{t('basket.basket')} {totalProducts ? <span className="count">({totalProducts})</span> : null} </Tab>
                    {/*<Tab><span className={"reserve"}></span>{t('basket.reserve')} {reserveCount ? <span className="count">({reserveCount})</span> : null}</Tab>*/}
                </TabList>

                <TabPanel>
                    <div className="cart-wrapper">
                    {(productsCart.length != 0) ? <Cart totalProductCart={productsCount} /> : <EmptyCart isReserve={false} />}
                    </div>
                    {(productsCart.length != 0) ? <div className={"pr-10"}>
                        {location == 'ua' ? <ExchangeRates /> : null}
                        <TotalOrder cart={productsCart} showVat={true} />
                        <button className={"btn-usual add-order"} onClick={addOrderAction}>{t('basket.add_order')}</button>
                        <NavLink
                            onClick={(e) => checkProductCount(e)}
                            to={'/new-order'}
                            className={productsCount == 0 ? "btn btn-lg checkout disable" : "btn btn-lg checkout"}>
                            {t('basket.checkout')}
                        </NavLink>
                        </div> : ''}

                </TabPanel>

                {/*<TabPanel>
                    {(productsReserve.orderItems.length != 0) ? <Reserve /> : <EmptyCart isReserve={true} />}
                    {(productsReserve.orderItems.length != 0) ? <div className={"pr-10"}>
                        <ExchangeRates />
                        <TotalOrder reserve={productsReserve} />
                        <NavLink
                            to={'/new-reserve'}
                            className="btn btn-lg checkout"
                        >
                            {t('basket.checkout_reserve')}
                        </NavLink>
                    </div> : ''}
                </TabPanel>*/}

            </Tabs>

        </div>
    )
}
export default Sidebar;