import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../hooks";
import {CartOrder} from "../../interfaces/CartProduct";
import {useTranslation} from "react-i18next";

const ProductCountSum:React.FC<{price: number, id: number}> = ({price, id}) => {

    const cart = useAppSelector<CartOrder[]>(state => state.cart.value)
    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)
    const currUser = useAppSelector((state) => state.user.value);

    const [activeCount, setActiveCount] = useState(0)
    const { t } = useTranslation();

    useEffect(() => {
        if (cart.length == 0) {
            setActiveCount(0)
            return;
        }
        let findCount = cart[activeOrder].orderItems.find(item => item.product.id == id)

        if(findCount){
            setActiveCount(findCount.count)
        }

    }, [activeOrder, cart])

    return (
        <span>{Number(price * (activeCount)).toFixed(2)} {t(currUser.currency)}</span>
    )
}
export default ProductCountSum