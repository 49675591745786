import React, {useEffect, useState} from "react";
import {NavLink,  useParams} from "react-router-dom";
import OrderTypes from "../../../components/OrderTypes/OrderTypes";
import KeyValuePaire from "../../../components/KeyValuePaire/KeyValuePaire";
import DeliverySavedAddress
    from "../../../components/OrderProducts/OrderProductsDelivery/delivery/DeliverySavedAddress/DeliverySavedAddress";
import {DeliveryPayer} from "../../../enums/DeliveryPayer";
import { FlexboxGrid} from "rsuite";
import {ProductEntity} from "../../../interfaces/Product/ProductEntity";
import TableOrder from "../../../components/TableOrder/TableOrder";
import TotalOrder from "../../../components/TotalOrder/TotalOrder";
import Footer from "../../../blocks/Footer";
import {useTranslation} from "react-i18next";
import GetOrderModel from "../../../dto/order/GetOrderModel";
import {useAppSelector} from "../../../hooks";
import VelotradeMobile from "../../../assets/img/Velotrade_mob.svg";
import orderService from "../../../services/orderService";


const OrderTrackPage: React.FC = () =>{
    const {t} = useTranslation();

    let { orderId } = useParams();

    const [order, setOrder] = useState<GetOrderModel>()
    const rate = useAppSelector(state => state.rate.value)

    useEffect(() => {
        if (orderId) {
            orderService.trackOrderStatus(orderId).then(data => {
                setOrder(data)
            })
        }
    }, [])
    return (
        <div className="my-order-single-wrapper page-wrapper">
            <div className="header-wrapper">
                <header>
                    <NavLink to="/" className={"logo"}>
                        <img src={VelotradeMobile} alt="Velotrade"/>
                    </NavLink>
                </header>

            </div>
            <main>
                <div className="my-order-single-header">
                    <div className="my-order-single-id">{t('orderSingle.order')} {order?.id}</div>
                    {order ? <OrderTypes status={order.status} /> : null }
                </div>

                <div className="info-order-wrapper">

                    <div className="info-order-detail-wrapper">
                        <div className="col col-1">

                            {order?.contactName ?
                                <KeyValuePaire label={t('finishedOrders.contactData')} value={order.contactName} />
                                : null}

                            {order?.contactPhone ?
                                <KeyValuePaire label={t('finishedOrders.phone')} value={order.contactPhone} />
                                : null}

                            {order?.deliveryAddress || order?.isPickup ? <DeliverySavedAddress
                                paymentPickupData={order.isPickup? {deliveryPayer: order.deliveryPayer,
                                    orderToPay:order.cashOnDeliveryAmount,
                                    carModel: order.carModel,
                                    carNumber: order.carNumber,
                                    pickupDate: order.pickupDate,
                                    pickupTime: order.pickupTime}: null}
                                savedAddress={order.deliveryAddress} labels={true} /> : null}

                            {order?.shippingInvoiceNumber ?
                                <div className="invoiceShipping">
                                    <KeyValuePaire label={t('orderSingle.shippingInvoiceNumber')} value={order.shippingInvoiceNumber} />
                                </div>
                                : null}

                            {order?.deliveryPayer != null ?
                                <div className="key-value-info">
                                    <div className="label">{t('profile.payer')}</div>
                                    <div className="value">
                                        {order.deliveryPayer == DeliveryPayer.Receiver ? t('deliveryPayer.receiver') :  t('deliveryPayer.sender')}
                                    </div>
                                </div>
                                : null}

                        </div>
                        <div className="col col-2">
                            {order?.cashOnDeliveryAmount ?
                                <KeyValuePaire label={t('profile.sum')} value={order.cashOnDeliveryAmount} />
                                : null}

                            <div className="delivery-volume">
                                <div className="delivery-volume-item">
                                    {order?.shippingPlaces ? <KeyValuePaire label={t('orderSingle.shippingPlaces')} value={order.shippingPlaces} />
                                        : null}
                                </div>
                                <div className="delivery-volume-item">
                                    {order?.width ? <KeyValuePaire label={t('orderSingle.width')} value={order.width} />
                                        : null}
                                </div>
                                <div className="delivery-volume-item">
                                    {order?.height ? <KeyValuePaire label={t('orderSingle.height')} value={order.height} />
                                        : null}
                                </div>
                                <div className="delivery-volume-item">
                                    {order?.weight ? <KeyValuePaire label={t('orderSingle.weight')} value={order.weight} />
                                        : null}
                                </div>

                            </div>

                            {order?.receiver ?
                                <KeyValuePaire label={t('finishedOrders.contactPerson')} value={order.receiver.lastName + ' ' + order.receiver.firstName} />
                                : null}

                            {order?.receiver ?
                                <KeyValuePaire label={t('finishedOrders.contactPhone')} value={order.receiver.phone} />
                                : null}

                            {order?.comment ?
                                <KeyValuePaire label={t('finishedOrders.comments')} value={order.comment} />
                                : null}
                        </div>
                    </div>


                </div>

                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <h2>{t('orderSingle.orderList')}</h2>
                    </FlexboxGrid.Item>

                </FlexboxGrid>


                <div className="order-details-wrapper">
                    <div className="table-orders">
                        {order ? <TableOrder
                            data={order?.items.map((item) => ProductEntity.CreateProductEntityOrder(item))}
                        /> : null}

                    </div>

                    <div className="price-wrapper">
                        <div className="price-currency-wrapper">

                            <div className="delivery-volume">
                                <div className="delivery-volume-item">
                                    {order?.shippingPlaces ?
                                        <KeyValuePaire label={t('currency')} value={'USD'} />
                                        : null}
                                </div>
                                <div className="delivery-volume-item">
                                    {order?.width ?
                                        <KeyValuePaire label={t('exchangeRates')} value={rate} />
                                        : null}
                                </div>
                            </div>

                        </div>

                        {order?.totalAmount ? <TotalOrder reserve={{orderId: order.id,
                            orderItems: order.items.map(i => ({product: {
                                    id: i.productId,
                                    vendorCode: i.vendorCode,
                                    photoUrl: '',
                                    name: i.productName,
                                    lastPurchaseDate: '',
                                    available: 0,
                                    recommendedRetailPrice: i.recommendedRetailPrice,
                                    price: i.price,
                                    lastPurchaseCount: 0,
                                    categoryId: 0
                                }, count: i.count}))}} showVat={false} /> : null}


                    </div>

                </div>

            </main>
            {/*<Footer />*/}
        </div>
    )
}
export default OrderTrackPage