import React, {useEffect, useState} from "react";

import {useTranslation} from "react-i18next";
import {useAppSelector} from "../../hooks";
import {CartOrder} from "../../interfaces/CartProduct";

import "./TotalOrder.sass"

const TotalOrder: React.FC<{cart?: CartOrder[] | null, reserve?: CartOrder | null, showVat: boolean }> = ({cart, reserve, showVat}) => {
    const { t } = useTranslation();

    const activeOrder = useAppSelector<number>(state => state.activeOrder.value)
    const rate = useAppSelector<number>(state => state.rate.value)
    const lang = useAppSelector<string>(state => state.currentLang.value)
    const currUser = useAppSelector((state) => state.user.value);
    const vatPercent = 23; //23%

    const [totalPrice, setTotalPrice] = useState('0');
    const [totalPriceCurrency, setTotalPriceCurrency] = useState('0');
    const [income, setIncome] = useState('0');
    const [recommendedRetailPrice, setRecommendedRetailPrice] = useState('0');
    const [totalProducts, setTotalProducts] = useState(0);
    const [vat, setVat] = useState('0');
    const [withoutVat, setWithoutVat] = useState('0');
    const location = process.env.REACT_APP_LOCATION ?? 'ua';

    const [productDeclination, setProductDeclination] = useState('')

    const case1 = t('productDeclination.case1')
    const case2 = t('productDeclination.case2')
    const case3 = t('productDeclination.case3')

    useEffect(() => {
        let twoDigits = totalProducts % 100

        if (twoDigits >= 10 && twoDigits <= 20) {
            setProductDeclination(case3)
            return
        }
        let digit = totalProducts % 10


        switch (digit) {
            case 1:  setProductDeclination(case1)
                break;
            case 2:
            case 3:
            case 4: setProductDeclination(case2)
                break;
            default: setProductDeclination(case3)
        }
    }, [totalProducts, lang])

    useEffect(() => {
        let lTotalPrice = 0;
        let lTotalProducts = 0;
        let lrrp = 0;
        if (cart && cart.length != 0) {
            cart[activeOrder].orderItems.forEach((product) => {
                lTotalPrice += product.product.price * product.count
                lTotalProducts += product.count
                lrrp += product.product.recommendedRetailPrice * product.count
            })
        }
        else if (reserve && reserve.orderItems.length != 0){
            reserve.orderItems.forEach((product) => {
                lTotalPrice += product.product.price * product.count
                lTotalProducts += product.count
                lrrp += product.product.recommendedRetailPrice * product.count
            })
        }
        setTotalPrice(Number(lTotalPrice).toFixed(2))
        setRecommendedRetailPrice(lrrp.toFixed(2))
        setTotalProducts(lTotalProducts)
        setTotalPriceCurrency(Number(location == "ua"? lTotalPrice * rate: lTotalPrice).toFixed(2))
        setIncome(Number(lrrp - lTotalPrice).toFixed(2))


        const lvat = (+lTotalPrice * vatPercent) / (100 + vatPercent);
        setVat((lvat).toFixed(2));

        const withoutVat = +lTotalPrice - lvat;
        setWithoutVat(withoutVat.toFixed(2))
    }, [cart, activeOrder, reserve])

    return (
        <div className="total-price">
            <div className={"d-flex space-between"}>
                <div className="description">{totalProducts} {productDeclination}:</div>
                <div className="price">{totalPrice} {t(currUser.currency)}</div>
            </div>

            <div className={"d-flex space-between"}>
                <div className="description">{t('basket.total_income')}:</div>
                <div className="price">{income} {t(currUser.currency)}</div>
            </div>

            {currUser.currency == 'PLN' && showVat ? <>
                <div className={"d-flex space-between"}>
                    <div className="description">{t('basket.to_payNoVat')}:</div>
                    <div className="price">{withoutVat} {t(currUser.rrpCurrency)}</div>
                </div>

                <div className={"d-flex space-between"}>
                    <div className="description">{t('basket.to_payVat')}:</div>
                    <div className="price">{vat} {t(currUser.rrpCurrency)}</div>
                </div>

                <div className={"d-flex space-between"}>
                    <div className="to-pay">{t('basket.to_pay')}</div>
                    <div className="to-pay">{totalPriceCurrency} {t(currUser.rrpCurrency)}</div>
                </div>
            </> : location === 'ua' ?
                <div className={"d-flex space-between"}>
                    <div className="to-pay">{t('basket.to_pay')}</div>
                    <div className="to-pay">{totalPriceCurrency} {t(currUser.rrpCurrency)}</div>
                </div> :
                <div className={"d-flex space-between"}>
                    <div className="to-pay">{t('basket.to_payNoVat')}</div>
                    <div className="to-pay">{totalPriceCurrency} {t(currUser.rrpCurrency)}</div>
                </div>
            }

        </div>
    )
}
export default TotalOrder;